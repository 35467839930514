<template>
  <div class="app-container">
    <el-drawer title="Search Filter" :visible.sync="drawerFilterVisible" direction="btt" size="50%">
      <div class="drawer">
        <el-form :ref="settings" label-width="100px" label-position="left">
          <el-form-item label="Employee">
            <el-select v-model="settings.staff" placeholder="Select Employee">
              <el-option value="0" label="All"></el-option>
              <el-option v-for="person in personnel" :key="person.em_id" :label="person.em_id_name" :value="person.em_id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Status">
            <el-select v-model="settings.status" placeholder="Select Approval Status">
              <el-option v-for="(stat, index) in statuses" :key="index" :value="stat.value" :label="stat.name"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div class="drawer-footer">
          <el-button @click="closeFilterDialog()">Cancel</el-button>
          <el-button type="primary" @click="confirmFilterDialog()">Confirm</el-button>
        </div>
      </div>
    </el-drawer>
    <el-dialog title="Approvers" :visible.sync="dialogTableVisible" :fullscreen="isMobile">
      <el-table :data="approvers">
        <el-table-column prop="hierarchy_no" width="50"></el-table-column>
        <el-table-column prop="boss_em_id_name" label="NAME">
          <template slot-scope="props">
            <div v-if="props.row.boss_em_id === $store.getters.user.employeeId"><b>{{props.row.boss_em_id_name}} (Me)</b></div>
            <div v-else>{{props.row.boss_em_id_name}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="a_id_name" label="STATUS">
          <template slot-scope="props">
           <el-tag :type="getStatusType(props.row.a_id_name)">{{ props.row.a_id_name }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="a_id_remark" label="REMARKS"></el-table-column>
        <el-table-column prop="a_date_action" label="DATE ACTIONED">
          <template slot-scope="scope">
            <i class="el-icon-time"></i>
            <span style="margin-left: 10px">{{ formatDate(scope.row.a_date_action) }}</span>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <el-dialog :title="modalTitle" :visible.sync="dialogDisapproveVisible">
      <el-form :model="form">
        <el-form-item label="Reason for disapproving">
          <el-input v-model="form.remarks" type="textarea" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeModal()">Cancel</el-button>
        <el-button type="primary" @click="update()">Confirm</el-button>
      </span>
    </el-dialog>
    <el-card>
      <div slot="header" class="card-header">
        <h3 style="margin: 10;">FILED FORMS APPROVAL LIST</h3>
        <el-button type="primary" icon="el-icon-user" @click="drawerFilterVisible = true">Search Filter</el-button>
      </div>
      <div class="controls">
        <div class="control">
          <p>Items per page: </p>
          <el-select v-model="items">
            <el-option :label="5" :value="5"></el-option>
            <el-option :label="10" :value="10"></el-option>
            <el-option :label="25" :value="25"></el-option>
            <el-option :label="50" :value="50"></el-option>
            <el-option :label="100" :value="100"></el-option>
          </el-select>
        </div>
        <div class="control">
            <el-button-group>
              <el-button type="primary" icon="el-icon-arrow-left" @click="prevPage()" :disabled="!hasPreviousPage">Previous Page</el-button>
              <el-button type="primary" @click="nextPage()" :disabled="!hasNextPage">Next Page<i class="el-icon-arrow-right el-icon-right"></i></el-button>
            </el-button-group>
        </div>
      </div>
      <el-collapse accordion class="hidden-md-and-up">
        <el-collapse-item v-for="(file, index) in data" :key="index" :name="file.id">
          <template slot="title">
            <p style="width: 100%">{{ file.name }} [{{ formatDate(file.date_filed) }}]</p>
          </template>
          <p>Application Status: <el-tag :type="getStatusType(file.file_status)">{{ file.file_status }}</el-tag></p>
          <div v-if="file.multi_leave.length">
            <p>Date/s Covered:</p>
            <ul>
              <li v-for="(leave, index) in file.multi_leave" :key="index">
              {{ leave.bil_id_name }},  {{ formatDate(leave.leave_date) }} [{{ leave.duration_bg4_id_name }}]
            </li>
            </ul>
          </div>
          <div v-else>
            <p>Date/s Covered</p>
            <ul>
              <li v-for="(date, index) in file.date_dates.split(';')" :key="index">
                {{ formatDate(date) }}
              </li>
            </ul>
          </div>
          <div v-if="parseInt(file.bft_id) !== 1">
            <p>Time Covered (From-To): <b>{{ file.time_from }} - {{ file.time_to }}</b></p>
          </div>
          <p>Total Number of File: <b>{{ file.total_number_file }}</b></p>
          <p>Reason/s: <b>{{ file.reason }}</b></p>
          <div class="collapse-buttons">
            <el-tooltip class="item" effect="dark" content="Approval Status" placement="top-start">
              <el-button el-button size="mini" type="primary" @click="showApprovalStatus(file.approvers)"><i class="el-icon-document-checked"></i></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="Approve" placement="top-start">
              <el-button size="mini" type="success" @click="showRemarksField(file, 2, 'Form Approved', 'Approving Form')" v-if="isCurrentApprover(file.approvers)"><i class="el-icon-check"></i></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="Disapprove" placement="top-start">
              <el-button size="mini" type="danger" @click="showRemarksField(file, 3, 'Form Disapproved', 'Disapproving Form')" v-if="isCurrentApprover(file.approvers)"><i class="el-icon-close"></i></el-button>
            </el-tooltip>
          </div>
        </el-collapse-item>
      </el-collapse>
      <el-table :data="data" border class="hidden-sm-and-down">
        <el-table-column type="expand">
          <template slot-scope="props">
          <div v-if="parseInt(props.row.bft_id) !== 1">
            <p class="expanded-p">Time Covered (From-To): <b>{{ props.row.time_from }} - {{ props.row.time_to }}</b></p>
          </div>
          <p class="expanded-p">Total Number of File: <b>{{props.row.total_number_file}}</b></p>
          <p class="expanded-p">Reason/s: <b>{{props.row.reason}}</b></p>
          </template>
        </el-table-column>
        <el-table-column label="EMPLOYEE NAME" prop="em_id_name" width="200">
        </el-table-column>
        <el-table-column label="APPLICATION TYPE" prop="name" width="200">
        </el-table-column>
        <el-table-column label="DATE FILED" prop="date_filed" width="200">
          <template slot-scope="props">
            <p>{{ formatDate(props.row.date_filed) }}</p>
          </template>
        </el-table-column>
        <el-table-column label="APPLICATION STATUS" prop="file_status" width="200">
          <template slot-scope="props">
           <el-tag :type="getStatusType(props.row.file_status)">{{ props.row.file_status }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="DATES COVERED" prop="date_dates" min-width="400">
          <template slot-scope="props">
            <div v-if="props.row.multi_leave.length">
              <p v-for="(leave, index) in props.row.multi_leave" :key="index" style="margin: 0;">
                {{ leave.bil_id_name }} - {{ leave.duration_bg4_id_name }} - {{ formatDate(leave.leave_date) }}
              </p>
            </div>
            <div v-else>
              <p v-for="date in props.row.date_dates.split(';')" :key="date" style="margin: 0;">
                {{ formatDate(date) }}
              </p>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="ACTIONS" fixed="right" align="left" width="180">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" content="Approval Status" placement="top-start">
              <el-button el-button size="mini" type="primary" @click="showApprovalStatus(scope.row.approvers)"><i class="el-icon-document-checked"></i></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="Approve" placement="top-start">
              <el-button size="mini" type="success" @click="showRemarksField(scope.row, 2, 'Form Approved', 'Approving Form')" v-if="isCurrentApprover(scope.row.approvers)"><i class="el-icon-check"></i></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="Disapprove" placement="top-start">
              <el-button size="mini" type="danger" @click="showRemarksField(scope.row, 3, 'Form Disapproved', 'Disapproving Form')" v-if="isCurrentApprover(scope.row.approvers)"><i class="el-icon-close"></i></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

<script>
import { format } from 'date-fns'

export default {
  name: 'FormsApproval',
  data () {
    return {
      windowWidth: window.innerWidth,
      all: [],
      data: [],
      dialogTableVisible: false,
      dialogApproveVisible: false,
      dialogDisapproveVisible: false,
      drawerFilterVisible: false,
      approvers: [],
      personnel: [],
      staff: null,
      status: null,
      items: 5,
      page: 1,
      hasNextPage: false,
      hasPreviousPage: false,
      statuses: [
        {
          value: 0,
          name: 'All'
        },
        {
          value: 1,
          name: 'Waiting'
        },
        {
          value: 2,
          name: 'Approved'
        },
        {
          value: 3,
          name: 'Disapproved'
        }
      ],
      form: {
        remarks: null
      },
      settings: {
        staff: null,
        status: null
      },
      file: null,
      statusId: null,
      snackbarResponse: null,
      modalTitle: null
    }
  },
  computed: {
    isMobile () {
      return this.windowWidth < 992
    }
  },
  watch: {
    items () {
      this.page = 1
      this.getMSSForms()
    }
  },
  mounted () {
    this.getMSSForms()
    this.getPersonnels()
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  methods: {
    getMSSForms () {
      const status = this.settings.status !== null ? this.settings.status : 1
      const staff = this.settings.staff !== null ? this.settings.staff : 0
      this.$http
        .get(`mss.forms-list/${this.$store.getters.user.employeeId}/${status}/${staff}/${this.items}?page=${this.page}`)
        .then(res => {
          this.master = res.data.StatusCode ? res.data.body.data : []
          this.data = res.data.StatusCode ? res.data.body.data : []
          this.hasNextPage = res.data.StatusCode ? !!res.data.body.next_page_url : false
          this.hasPreviousPage = res.data.StatusCode ? !!res.data.body.prev_page_url : false
        })
    },
    nextPage () {
      this.page++
      this.getMSSForms()
    },
    prevPage () {
      this.page--
      this.getMSSForms()
    },
    getPersonnels () {
      this.$http
        .get(`mss.personnels-list/${this.$store.getters.user.employeeId}`)
        .then(res => {
          this.personnel = res.data.body
        })
    },
    showApprovalStatus (approvers) {
      this.approvers = approvers
      this.dialogTableVisible = true
    },
    formatDate (date, dateFormat = 'iii, dd MMM yyyy') {
      if (date) {
        return format(new Date(date), dateFormat)
      } else {
        return ''
      }
    },
    update () {
      const currentApprover = this.file.approvers.find(approver => parseInt(approver.boss_em_id) === parseInt(this.$store.getters.user.employeeId))
      let nextApprover = null
      if (parseInt(currentApprover.hierarchy_no) !== 1) {
        nextApprover = this.file.approvers.find(approver => parseInt(approver.hierarchy_no) === parseInt(currentApprover.hierarchy_no) - 1)
      } else {
        nextApprover = {
          id: 1
        }
      }
      const form = {
        id: this.file.id,
        bft_id: this.file.bft_id,
        total_number_file: this.file.total_number_file,
        esa_id: currentApprover.id,
        hierarchy_no: currentApprover.hierarchy_no,
        next_id: nextApprover.id,
        a_id: this.statusId,
        a_id_remark: this.form.remarks
      }
      this.form.remarks = null
      this.$http
        .post('mss.forms/approval', form)
        .then(res => {
          this.$message({
            type: 'success',
            message: this.snackbarResponse
          })
          this.closeModal()
          this.getMSSForms()
        })
    },
    closeModal () {
      this.file = null
      this.statusId = null
      this.snackbarResponse = null
      this.modalTitle = null
      this.dialogDisapproveVisible = false
      this.form.remarks = null
    },
    showRemarksField (file, statusId, snackbarResponse, modalTitle) {
      this.file = file
      this.statusId = statusId
      this.snackbarResponse = snackbarResponse
      this.modalTitle = modalTitle
      if (statusId === 3) {
        this.dialogDisapproveVisible = true
      } else {
        this.$confirm('Are you sure you want approve this form?', 'Confirm', {
          confirmButtonText: 'Yes',
          cancelButtonText: 'Cancel',
          type: 'info'
        }).then(() => {
          this.update()
        }).catch((e) => {
          this.$message({
            type: 'info',
            message: 'Deletion cancelled'
          })
        })
      }
    },
    getStatusType (type) {
      if (type === 'Waiting') {
        return 'primary'
      } else if (type === 'Approved') {
        return 'success'
      } else {
        return 'danger'
      }
    },
    searchForm (id) {
      this.data = this.master.filter(form => form.em_id === id)
    },
    isCurrentApprover (approvers) {
      const approver = approvers.find(approver => parseInt(approver.boss_em_id) === parseInt(this.$store.getters.user.employeeId))
      return parseInt(approver.is_action_taken)
    },
    confirmFilterDialog () {
      this.drawerFilterVisible = false
      this.getMSSForms()
    },
    closeFilterDialog () {
      this.drawerFilterVisible = false
      this.form.staff = null
      this.form.year = null
    },
    onResize () {
      this.windowWidth = window.innerWidth
    },
    beforeDestroy () {
      window.removeEventListener('resize', this.onResize)
    }
  }
}
</script>

<style lang="scss" scoped>
  .el-table {
    overflow: auto;
  }
  .expanded-p {
    margin: 0;
  }
  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
</style>
